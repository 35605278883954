export const rocketMan = `She packed my bags last night, pre-flight
Zero hour, 9 a.m.
And I'm gonna be high
As a kite by then

I miss the Earth so much, I miss my wife
It's lonely out in space
On such a timeless flight

And I think it's gonna be a long, long time
'Til touchdown brings me 'round again to find
I'm not the man they think I am at home
Oh, no, no, no
I'm a rocket man
Rocket man, burning out his fuse up here alone

And I think it's gonna be a long, long time
'Til touchdown brings me 'round again to find
I'm not the man they think I am at home
Oh, no, no, no
I'm a rocket man
Rocket man, burning out his fuse up here alone

Mars ain't the kind of place to raise your kids
In fact, it's cold as hell
And there's no one there to raise them
If you did

And all this science, I don't understand
It's just my job five days a week
A rocket man
A rocket man

And I think it's gonna be a long, long time
'Til touchdown brings me 'round again to find
I'm not the man they think I am at home
Oh, no, no, no
I'm a rocket man
Rocket man, burning out his fuse up here alone

And I think it's gonna be a long, long time
'Til touchdown brings me round again to find
I'm not the man they think I am at home
Oh, no, no, no
I'm a rocket man
Rocket man, burning out his fuse up here alone

And I think it's gonna be a long, long time
And I think it's gonna be a long, long time
And I think it's gonna be a long, long time
And I think it's gonna be a long, long time
And I think it's gonna be a long, long time
And I think it's gonna be a long, long time
And I think it's gonna be a long, long time
And I think it's gonna be a long, long time`;
