export const heyJude = `Hey Jude, don't make it bad
Take a sad song and make it better
Remember to let her into your heart
Then you can start to make it better

Hey Jude, don't be afraid
You were made to go out and get her
The minute you let her under your skin
Then you begin to make it better

And any time you feel the pain
Hey Jude, refrain
Don't carry the world upon your shoulder
For well you know that it's a fool
Who plays it cool
By making his world a little colder

Na, na, na, naa-naa
Na-na-naa, naaa

Hey Jude, don't let me down
You have found her, now go and get her (let it out and let it in)
Remember (hey Jude) to let her into your heart
Then you can start to make it better

So let it out and let it in
Hey Jude, begin
You're waiting for someone to perform with
And don't you know that it's just you
Hey Jude, you'll do
The movement you need is on your shoulder
Na-na-na, na
Na-na-na, na, yeah

Hey Jude, don't make it bad
Take a sad song and make it better
Remember to let her under your skin
Then you'll begin to make it better

Better, better, better, better (I'm begging you) better, oh!

Na, na, na, na-na-na-naa (yeah, yeah, yeah, yeah, yeah, yeah)
Na-na-na-naa, hey, Jude
Na, na, na, na-na-na-naa
Na-na-na-naa, hey, Jude

Na, na, na, na-na-na-naa
Na-na-na-naa, hey, Jude (Jude)
Na, na, na, na-na-na-naa
Na-na-na-naa, hey, Jude (Jude, Jude, a Jude, a Jude, a Juda-Juda)

Na, na, na, na-na-na-naa
Na-na-na-naa, hey, Jude (Jude, Jude, Jude, Jude, Jude)
Na, na, na, na-na-na-naa (yeah, yeah, yeah, yeah)
Na-na-na-naa, hey, Jude (Hey, you know you can make it, Jude)
Jude, you're not gonna break it

Na, na, na, na-na-na-naa (don't make it bad, Jude)
Na-na-na-naa, hey, Jude (take a sad song and make it better)
Jude, hey Jude, woah!
Na, na, na, na-na-na-naa (Jude)
Na-na-na-naa, hey, Jude (yeah, hey, hey, hey, hey)

Na, na, na, na-na-na-naa
Na-na-na-naa, hey, Jude (now Jude, Jude, Jude, Jude, Jude, Jude!)
Na, na, na, na-na-na-naa (yeah, yeah, yeah, yeah)
Na-na-na-naa, hey, Jude
Na, na, na, na-na-na-naa
Na-na-na-naa, hey, Jude (na-na-na-na-na-na-na-na, la-la-la-la-la-la-la-la)

Na, na, na, na-na-na-naa
Na-na-na-naa, hey, Jude
Na, na, na, na-na-na-naa
Na-na-na-naa, hey, Jude
Na, na, na, na-na-na-naa
Na-na-na-naa, hey, Jude (yeah, yeah, yeah, yeah)

Na, na, na, na-na-na-naa (yeah, yeah, yeah, yeah)
Na-na-na-naa, hey, Jude
Na, na, na, na-na-na-naa
Na-na-na-naa, hey, Jude`;