export const angels = `I sit and wait
Does an angel contemplate my fate
And do they know
The places where we go
When we're grey and old
'Cause I have been told
That salvation lets their wings unfold

So when I'm lying in my bed
Thoughts running through my head
And I feel the love is dead
I'm loving angels instead

And through it all she offers me protection
A lot of love and affection
Whether I'm right or wrong
And down the waterfall
Wherever it may take me
I know that life won't break me
When I come to call, she won't forsake me
I'm loving angels instead

When I'm feeling weak
And my pain walks down a one way street
I look above

And I know I'll always be blessed with love
And as the feeling grows
She breathes flesh to my bones
And when love is dead

I'm loving angels instead

And through it all she offers me protection
A lot of love and affection
Whether I'm right or wrong
And down the waterfall
Wherever it may take me
I know that life won't break me
When I come to call, she won't forsake me

I'm loving angels instead

And through it all she offers me protection
A lot of love and affection
Whether I'm right or wrong
And down the waterfall
Wherever it may take me
I know that life won't break me
When I come to call, she won't forsake me
I'm loving angels instead`;
