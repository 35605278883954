import { tearsInHeaven } from "./tearsInHeaven";
import { africa } from "./africa";
import { angels } from "./angels";
import { egVeTeBergen } from "./egVeTeBergen";
import { hotelCalifornia } from "./hotelCalifornia";
import { heyJude } from "./heyJude";
import { mostBeuatifulGirl } from "./mostBeautifulGirl";
import { rocketMan } from "./rocketMan";
import { krasafarenSteinbu } from "./krasafarenSteinbu";
import { rumbaMedGunn } from "./rumbaMedGunn";
import { kanskjeKommerKongen } from "./kanskjeKommerKongen";
import { vinsjanPaaKaia } from "./vinsjanPaaKaia";
import { optimist } from "./optimist";
import { rampa } from "./rampa";
import { liftMe } from "./liftMe";
import { iWantItThatWay } from "./iWantItThatWay";
import { purpleRain } from "./purpleRain";
import { lysOgVarme } from "./lysOgVarme";

const lyrics = {
  tearsInHeaven: tearsInHeaven,
  africa: africa,
  angels: angels,
  egVeTeBergen: egVeTeBergen,
  hotelCalifornia: hotelCalifornia,
  heyJude: heyJude,
  mostBeautifulGirl: mostBeuatifulGirl,
  rocketMan: rocketMan,
  krasafarenSteinbu: krasafarenSteinbu,
  rumbaMedGrunn: rumbaMedGunn,
  kanskjeKommerKongen: kanskjeKommerKongen,
  vinsjanPaaKaia: vinsjanPaaKaia,
  optimist: optimist,
  rampa: rampa,
  liftMe: liftMe,
  iWantItThatWay: iWantItThatWay,
  purpleRain: purpleRain,
  lysOgVarme: lysOgVarme,
};

export default lyrics;
