export const krasafarenSteinbu = `Eg skull' te fjells for å jakte rein
Bussen va ganske sein, eg skulde gå te Hein
Været va ruskut og tungt og grått
Lendet va sleipt og vått
Sjøl vart eg redd so smått
Hadde hverken kart ell kompass
Burde jaddi hugst på sopass
Jævlig dumt syns væl du?

Måse og stein dæ va allt eg såg
Skodda kom sigande tjukk, so tung og grå
Jeger'n va liten og Vidda stor
Hadde 'kji leger ror
Trengde ein storebror
Eg tok te miste true
På at eg skull' finne bue
Klukka gjikk, huttetu, va snart sju!

Men då kom eg te ei krasafaren steinbu
Eine veggen sigji ut, men taket nokolein
Sette meg og fann fram nista
Våt og kald so kroppen rista
Likevæl va eg glad!
Åfto e livet som ein skoddeheim
Vegen e langtfrå bein
Eg kan 'kji finne heim
Rota på rundt i eit narrespel
Kjempa imot eit bel
Lyg meg ifrå eindel
Når det e som aller svartast
Når det bles med krappe vindkast
Når eg går mot eit stup, ni eit djup

Då kjem eg te ei krasafaren steinbu
Eine veggen sigji ut, men taket nokolein
Set meg ned og finn fram nista
Våt og kald so kroppen rista
Likevæl e eg glad! Eg e glad!

Då kjem eg te ei krasafaren steinbu
Eine veggen sigji ut, men taket nokolein
Set meg ned og finn fram nista
Våt og kald so kroppen rista
Likevæl e eg glad!
Ja, likevæl e eg glad!
Ja, likevæl e eg glad`;
