export const kanskjeKommerKongen = `Kanskje kommer kongen
Hit til middag nå i dag
Vi har dekket på perrongen
Med god mat av alle slag

[Vers 1]
Majones og gåselever
Med rødbeter og løk
Det beste skal på bordet
Med kongen på besøk

[Chorus]
Kanskje kommer kongen
Kanskje ridende til hest
Hesten skal få smellbongbongen
For da passer hatten best

[Vers 2]
Kalkun panert med hummer
Små glass med artisjokk
Knutsen skal servere
Og Ludvigsen er kokk
[Vers 3]
Når vi kommer til desserten
Får vi drops med karamell
Det er ikke bra for tenner
Det er best vi tar den selv

[Chorus]
Kanskje kommer kongen
I en bil med stram sjåfør
Han kan blåse opp ballongen
Slik sjåfører alltid gjør

[Bro]
Men kanskje kommer'n ikke
All vår oppdekning til tross
Det gjør ikke så mye
For da blir det mer til oss, ja

[Chorus]
Kanskje kommer kongen
Hit til middag nå i dag
Vi har dekket på perrongen
Med god mat av alle slag
Kanskje kommer kongen
Hit til middag nå i dag
Vi har dekket på perrongen
Med god mat av alle slag
Kanskje kommer kongen
Hit til middag nå i dag
Vi har dekket på perrongen
Med god mat av alle slag`;
