export const iWantItThatWay = `You are my fire
The one desire
Believe when I say
I want it that way

But we are two worlds apart
Can't reach to your heart
When you say
That I want it that way

Tell me why
Ain't nothing but a heartache
Tell me why
Ain't nothing but a mistake
Tell me why
I never wanna hear you say
I want it that way

Am I your fire
Your one desire
Yes I know it's too late
But I want it that way

Tell me why
Ain't nothing but a heartache
Tell me why
Ain't nothing but a mistake
Tell me why
I never wanna hear you say
I want it that way

Now I can see that we're falling apart
From the way that it used to be, yeah
No matter the distance
I want you to know
That deep down inside of me

You are my fire
The one desire
You are
You are, you are, you are

Don't wanna hear you say
Ain't nothing but a heartache
Ain't nothing but a mistake (don't wanna hear you say)
I never wanna hear you say (oh, yeah)
I want it that way

Tell me why
Ain't nothing but a heartache
Tell me why
Ain't nothing but a mistake
Tell me why
I never wanna hear you say (don't wanna hear you say)
I want it that way

Tell me why
Ain't nothing but a heartache
Ain't nothing but a mistake
Tell me why
I never wanna hear you say (never wanna hear you say)
I want it that way

'Cause I want it that way `;
