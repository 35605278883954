export const rampa = `Hain Åge skoill hoild konsert i adventstia
Åt'n hofstad på rampa i hommelvika
Æ va innleid som backuptrubaduuur
Men æ vest itj at'n Åge skoill bli sur
Nervøs va æ der i go'tia
Kansje fekk æ mæ nå'n ord med sjølve norgesstjerna
I lufta lå d speinning og sus og backstage så veilta æ ein brus
Og d knake i trappa og Åge kjæm ned
Med pondus og rockehabbitus
Skræmt spratt æ opp og strækt fram ei haind
For å hæll's
Men æ vart taus for'n åge sa:
Kæm e d som har veilta dein cola'n her!
D e da ingen som drekk cola i d BÆÆÆÆNDET her!
Cola på gittarbæggen min
Dein som æ nættopp kjøpt i byn
No va rommet fylt med BANDMEDLEMMA
Roadies og læmpis og managera
Og d som skjedd d va itj t å tru
Når'n Åge kom ned trappa med sett CREW
BACKUPTRUBADUR'N hain gjekk opp i røyk
Og for som en sky oppgjænnom trappa
Og tørsa itj å kom inn igjenn
Før'n Åge har spælt Langt ut på natta
Kæm e d som har veilta dein cola'n her!
D e da ingen som drekk cola i d BÆÆÆÆNDET her!
Cola på gittarbæggen min!
Dein som va så ny og fin!
Kæm e d som har veilta dein cola'n her!
Coooola utover heile gålve her!
Cola på gittarbæggen min
Dein som æ nættopp kjøpt i byn`;
