export const mostBeuatifulGirl = `Yeah-ahh...
Looking round the room,
I can tell that you
Are the most beautiful girl in the...room.
In the whole wide room
Oooh.

And when you're on the street
Depending on the street
I bet you are definitely in the top three
Good looking girls on the street...yeah...
And depending on the street, ooh...
And when I saw you at my mate's place
I thought what...is...she...doing...
At my mate's place
How did Dave get a hottie like that to a party like this?

Good one Dave!!!
Ooohhhh you're a legend, Dave!
I asked Dave if he's going to make a move on you
He's not sure
I said "Dave do you mind if I do?"
He says he doesn't mind
but I can tell he kinda minds
but I'm gonna do it anyway
I see you standing all alone by the stereo

I dim the lights down very low, here we go
You're so beautiful (beautiful)
You could be a waitress
You're so beautiful (beautiful)
You could be an air hostess in the 60s
You're so beautiful-ul-ul...
You could be a part...time...model

And then I seal the deal
I do my moves
I do my dance moves
Both of my dance moves
Ohh-ohh-ohh, ohh-ohh-ohh!

It's twelve-oh-two
Just me and you
And seven other dudes
Around you on the dance floor
I draw you near
Let's get outta here

Let's get in a cab
I'll buy you a kebab!
Now I can't believe
That I'm sharing a kebab with the most beautiful girl I have ever seen
With a kebab
Ooooooohhh.

Why don't we leave?
Let's go to my house and we can feel each other up on the couch
Oh no. I don't mind taking it slow-ho-ho, no-ho-ho, yeah.

Cause you're so beautiful
Like a, tree
Or a high-class prostitute
You're so beautiful-ul-ul...
Mmm, you could be a part-time model

But you'd probably have to keep your normal job
A part-time model!
Spending part of your time, modeling,
and part of your time, next to meeeeeeeeee!
And the rest of your time doing your normal job...
Ooh...ohh...ooh.
My place is usually tidier than this... `;
