export const tearsInHeaven = `Would you know my name?
If I saw you in heaven
Would it be the same?
If I saw you in heaven

I must be strong
And carry on
'Cause I know I don't belong
Here in heaven

Would you hold my hand?
If I saw you in heaven
Would you help me stand?
If I saw you in heaven

I'll find my way
Through night and day
'Cause I know I just can't stay
Here in heaven

Time can bring you down
Time can bend your knees
Time can break your heart
Have you begging please
Begging please

Beyond the door
There's peace, I'm sure
And I know there'll be no more
Tears in heaven

Would you know my name?
If I saw you in heaven
Would you be the same?
If I saw you in heaven

I must be strong
And carry on
'Cause I know I don't belong
Here in heaven`;
