export const rumbaMedGunn = `I storsalen på Folkets Hus med ei sløyfe som er rød
Sitter danseskolens minste mann og vet han snart skal dø
For fru Sveveland har sagt: små piker engasjer
Men de er slett ikke så små, og dessuten er de flere
Og nå hører han den fjerne torden av en bøffelflokk
Det harmer stilett mot parkett når horden går amok
I stor forferdelse griper han naboens hånd
Og visker et stille; mor må det ende sån?
1, 2, 3
Til siden, frem og en fot ned
Parketten knirker, no' tunkt er på vei
Og med tennene blottet som til et smil nermer det seg
Og på 1, 2 ,3
Så har hun festet sitt grep
Vist slikt kan kalles hun?
Hun er på størrelse med en liten traktor og heter Gunn
Hun er kretsmester i allt som er tungt og skal hives langt
Han ser på henne og tenker: dette kan bli interessant
1, 2, 3
Til siden, frem og en fot ned
Parketten knirker, no' tungt er på vei
Og med tennene blottet som til et smil nærmer det seg
Og på 1, 2 ,3
Og mårningen er stille i storsalen på Folkets Hus
Bare vaktmester Løn som suger ettertenksomt på en snus
Han har nettopp vaska gulvet,utenfor faller snø
I håndå så holder'n ei sløyfe som er, rød
1, 2, 3
Til siden, frem og en fot ned
Og ingen her har hørt fra han på en stund
Sist sett i en brennhet rumba med Gunn
Og på 1, 2, 3
1, 2, 3
Til siden, frem og en fot ned
Parketten knirker, no' tungt er på vei`;
