export const egVeTeBergen = `Eg ve te Bergen, ve te Bergen med det samme
Der har eg det så fisken i vannet:
Vått og kaldt, og breiflabb overalt

Å leve går an
Uten Brann
Og uten bauekorps og vannkopper på Haukeland
Eg e glad i godt vêr
Og torre kler
Men likevel så sønger eg så ofte så eg kan:

Eg ve te Bergen, ve te Bergen med det samme
Der har eg det så fisken i vannet:
Vått og kaldt, og breiflabb overalt

Det fins mykje å se
Det e'kje det
Og ikkje savner eg vel fisketorv og Laksevåg
Og Laksevågfergen og
Nei mitt problem
Eg ve hem
E at eg savner nokken eg
Så eg kan prate i munnen på
Så det ikkje går håll i hovvet på
For de prater like mykkje de og`;
