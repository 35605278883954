export const vinsjanPaaKaia = `Vi kika på båta fra Bergen og Murmansk og Mo
Med kaffe og stål og bokstava som vi ikke forsto'
Æ skull' bli styrmann når æ vart stor
Forlovelsesring skull' du få når æ for
Men no hadd æ billett te tanta mi i Vikna

Beatles spællt aldri så vakkert som vinsjan på kaia
Finast parfymen va lukta tå olje og sjø
Ingen va deilig som du, deinn dan æ skull' færra
Begge va altfor blyg te å sei adjø

Æ hadd fått hyre, vi sto bak no'n kassa og klina
Æ lova dæ pakke med silketørkle fra Kina
Det regna, men ringen din glinsa som sol
Da du tørka ei tåre mens æ gikk ombord
Som førstereis dekksgut te Trondhjem me trelast

Beatles spællt aldri så vakkert som vinsjan på kaia
Finast parfymen va lukta tå olje og sjø
Ingen va deilig som du, deinn dan æ skull' færra
Begge va altfor blyg te å sei adjø

Den siste sjømann har mønstra av
Vår ære og makt fann ei fuktig grav
Og du fann en kontorist fra Steinkjer

Beatles spællt aldri så vakkert som vinsjan på kaia
Finast parfymen va lukta tå olje og sjø
Ingen va deilig som du, deinn dan æ skull' færra
Begge va altfor blyg te å sei adjø
Beatles spællt aldri så vakkert som vinsjan på kaia
Finast parfymen va lukta tå olje og sjø
Ingen va deilig som du, deinn dan æ skull' færra
Begge va altfor blyg te å sei adjø`;
