export const purpleRain = `I never meant to cause you any sorrow
I never meant to cause you any pain
I only wanted one time to see you laughing

I only want to see you laughing
In the purple rain

Purple rain, purple rain
Purple rain, purple rain
Purple rain, purple rain
I only want to see you bathing
In the purple rain

I never wanted to be your weekend lover
I only wanted to be some kind of friend, hey
Baby, I could never steal you from another
It's such a shame our friendship had to end

Purple rain, purple rain
Purple rain, purple rain
Purple rain, purple rain
Only wanted to see you underneath the purple rain

Honey, I know, I know, I know times are changing
It's time we all reach out for something new
That means you too
You say you want a leader
But you can't seem to make up your mind

And I think you better close it
And let me guide you to the purple rain

Purple rain, purple rain, yeah
Purple rain, purple rain
If you know what I'm singing about up here
Come on, raise your hand
Purple rain, purple rain
I only want to see you
Only want to see you in the purple rain`;
