export const optimist = `Jeg står i mørke og skimter lyset i det fjerne
Det er så kaldt jeg søker varme fra en stjerne
Jeg tenker opp når jeg er nede for jeg er en optimist

Jeg har et håp hver gang jeg går omkring i blinde
Det finnes en sol i oss som snart skal begynne å skinne
Som får meg opp når jeg er nede om og om og om igjen

Jeg har en drøm at vi tar vare på hverandre
Og gir det beste i oss selv til alle andre
Jeg tenker opp når jeg er nede om å om å om igjen

Optimist, jeg vet det går bra til sist
Så lenge jeg lever her er jeg en optimist
Jeg er en optimist

Jeg har en tro jeg har ett mål som jeg skal finne
Jeg har en kraft og det er viljen til å vinne
Det får meg opp når jeg er nede om og om og om igjen

Vil at livet blir en dans i lyset
En dans for håpet og en dans for gleden
En dans i frihet og en dans for freden
Det skal gåååååååååå
Optimist, jeg vet det går bra til sist
Så lenge jeg lever her er jeg en optimist

Optimist, jeg vet det går bra til sist
Så lenge jeg lever her er jeg en optimist

Optimist, jeg vet det går bra til sist
Så lenge jeg lever her er jeg en optimist
Jeg er en optimist`;
