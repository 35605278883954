export const liftMe = `Lift me, lift me from the ground
And don't ever put me down, oh no
And don't pick a fight with me
Just flip a coin my love, you've won

Oh Love, slips her hand inside my hand
Oh Love, slips her hand inside my hand
I don't care if you don't want me
I'm yours, I'm yours right now

Our years, are years well spent
We may never find out where they went, oh no
And I don't mean to fight with you
Now all our troubles and all our struggles they are through

Oh Love, slips her hand inside my hand
Oh Love, slips her hand inside my hand
I don't care if you don't want me
I'm yours, I'm yours right now

The wolf and the fox
They're sleeping soundly with the elk and the ox
There's a starry field around
This lowlit kingdom
Where all our defences are down

Oh Love, slips her hand inside my hand
Oh Love, slips her hand inside my hand
I don't care if you don't want me
I'm yours, I'm yours right now

Wrecked, poor, naked and blind
I'm yours, I'm yours, right now
`;
