import { marked } from "marked";
import { Link } from "react-router-dom";
import styles from './song.module.css'

export default function Song({ title, lyrics }) {
  return (
    <main className={styles.content}>
      <h2>{title}</h2>
      <div
        dangerouslySetInnerHTML={{ __html: marked(lyrics, { breaks: true }) }}
      />
      <Link to="/" className={styles.link}>Tilbake</Link>
    </main>
  );
}
