import { Link } from "react-router-dom";
import styles from './App.module.css'

function App() {
  const links = [
    {
      slug: "tears-in-heaven",
      name: "Tears In Heaven",
    },
    {
      slug: "africa",
      name: "Africa",
    },
    {
      slug: "hotel-california",
      name: "Hotel California",
    },
    {
      slug: "hey-jude",
      name: "Hey, Jude",
    },
    {
      slug: "most-beautiful-girl",
      name: "Most Beautiful Girl (In the Room)",
    },
    {
      slug: "rocket-man",
      name: "Rocket Man",
    },
    {
      slug: "krasafaren-steinbu",
      name: "Krasafaren Steinbu",
    },
    {
      slug: "rumba-med-gunn",
      name: "Rumba med Gunn",
    },
    {
      slug: "kanskje-kommer-kongen",
      name: "Kanskje kommer kongen",
    },
    {
      slug: "vinsjan-paa-kaia",
      name: "Vinsjan på kaia",
    },
    {
      slug: "optimist",
      name: "Optimist",
    },
    {
      slug: "rampa",
      name: "Rampa (Cola'n her)",
    },
    {
      slug: "lift-me",
      name: "Lift Me",
    },
    {
      slug: "angels",
      name: "Angels",
    },
    {
      slug: "that-way",
      name: "I Want It That Way",
    },
    {
      slug: "purple-rain",
      name: "Purple Rain",
    },
    {
      slug: "lys-og-varme",
      name: "Lys og varme",
    },
    {
      slug: "bergen",
      name: "Eg ve te Bergen",
    },
  ];

  return (
    <nav className="App">
      {links.map((link) => (
        <Link to={link.slug} key={link.slug} className={styles.navLink}>
          {link.name}
        </Link>
      ))}
    </nav>
  );
}

export default App;
