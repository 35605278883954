export const lysOgVarme = `Når mørke no har sænka sæ
Går æ stillt igjennom rommet
Å følelsan dæm slit I'mæ
Ka vil fremtida gi?
Å den arven vi har gitt dæ
Kainn vær tung å ta me sæ
Vil du spørr oss, vil du last oss?
Vil du kaill det førr et svik?
Sola som gikk ned I kveld
Ho ska skin førr dæ min kjære
Å føglan som e fri, dæm ska vis vei å aillt ska bli
Myttji lys å myttji varme
Tru å håp det kan du få med
Mange tåra, tunge stunde, e æ redd førr at det bli
Når sola jage natta bort
Så kryp du godt innte mæ
Å gjømte de tunge tankan
Dæm æ hadd I går
I liv og latter spør du mæ
Om rægne og om sola
Å svaran som æ gir dæ e it jdæm æ hadd I går
Å sola som gikk ned I kveld
Ho ska skin førr dæ min kjære
Å føglan som e fri, dæm ska vis vei å aillt ska bli
Myttji lys å myttji varme
Tru å håp det kan du få med
Mange tåra, tunge stundе, e æ redd førr at det bli
Å sola som gikk nеd I kveld
Ho ska skin førr dæ min kjære
Å føglan som e fri, dæm ska vis vei å aillt ska bli
Myttji lys å myttji varme
Tru å håp det kan du få med
Mange tåra, tunge stunde, e æ redd førr at det bli
Sola som gikk ned I kveld
Ho ska skin førr dæ min kjære
Å føglan som e fri, dæm ska vis vei å aillt ska bli
Myttji lys å myttji varme
Tru å håp det kan du få med
Mange tåra, tunge stunde, e æ redd førr at det bli`;
