import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Song from "./Song";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import lyrics from "./songs";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <h1>30 og svetti</h1>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route
          path="tears-in-heaven"
          element={
            <Song title="Tears In Heaven" lyrics={lyrics.tearsInHeaven} />
          }
        />
        <Route
          path="africa"
          element={<Song title="Africa" lyrics={lyrics.africa} />}
        />
        <Route
          path="hotel-california"
          element={
            <Song title="Hotel California" lyrics={lyrics.hotelCalifornia} />
          }
        />
        <Route
          path="hey-jude"
          element={<Song title="Hey Jude" lyrics={lyrics.heyJude} />}
        />
        <Route
          path="most-beautiful-girl"
          element={
            <Song
              title="Most Beautiful Girl (In the Room)"
              lyrics={lyrics.mostBeautifulGirl}
            />
          }
        />
        <Route
          path="rocket-man"
          element={<Song title="Rocket Man" lyrics={lyrics.rocketMan} />}
        />
        <Route
          path="krasafaren-steinbu"
          element={
            <Song
              title="Krasafaren Steinbu"
              lyrics={lyrics.krasafarenSteinbu}
            />
          }
        />
        <Route
          path="rumba-med-gunn"
          element={
            <Song title="Rumba med Gunn" lyrics={lyrics.rumbaMedGrunn} />
          }
        />
        <Route
          path="kanskje-kommer-kongen"
          element={
            <Song
              title="Kanskje kommer kongen"
              lyrics={lyrics.kanskjeKommerKongen}
            />
          }
        />
        <Route
          path="vinsjan-paa-kaia"
          element={
            <Song title="Vinsjan på kaia" lyrics={lyrics.vinsjanPaaKaia} />
          }
        />
        <Route
          path="optimist"
          element={<Song title="Optimist" lyrics={lyrics.optimist} />}
        />

        <Route
          path="rampa"
          element={<Song title="Rampa" lyrics={lyrics.rampa} />}
        />
        <Route
          path="lift-me"
          element={<Song title="Lift Me" lyrics={lyrics.liftMe} />}
        />
        <Route
          path="angels"
          element={<Song title="Angels" lyrics={lyrics.angels} />}
        />
        <Route
          path="that-way"
          element={<Song title="I Want It That Way" lyrics={lyrics.iWantItThatWay} />}
        />
        <Route
          path="purple-rain"
          element={<Song title="Purple Rain" lyrics={lyrics.purpleRain} />}
        />
        <Route
          path="lys-og-varme"
          element={<Song title="Lys og varme" lyrics={lyrics.lysOgVarme} />}
        />
        <Route
          path="bergen"
          element={<Song title="Eg ve te Bergen" lyrics={lyrics.egVeTeBergen} />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
